import { useCallback, useEffect, useState } from "react";
import "../../../styles/ArrowUp.css"

const ArrowUp = () => {
    const [y, setY] = useState(window.scrollY);
    const [showArrow, setShowArrow] = useState(false);

    const handleNavigation = useCallback((e: any) => {
        const window = e.currentTarget;
        if(y > 200) {
            setShowArrow(true);
        } else {
            setShowArrow(false);
        }
        setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    return (
        <div 
        className="arrow-up-div"
        style={showArrow? {transform: "translate(0, 0)"}: {transform: "translate(0, 100px)"}}
        >
        <img
        className="arrow-up"
        src="/images/MainPage/arrow-left.png"
        onClick={scrollTop}
        alt="Arrow that scrolls up the page to the top."
        />
        </div>
    );
};

export default ArrowUp;