import Header from "../components/MainPage/Header";
import Hero from "../components/MainPage/Hero";
import Montessori from "../components/MainPage/Montessori";
import AboutUs from "../components/MainPage/AboutUs";
import Edu from "../components/MainPage/Edu";
import Metoda from "../components/MainPage/Metoda";
import Fundacja from "../components/MainPage/Fundacja";
import Contact from "../components/MainPage/Contact";
import Footer from "../components/MainPage/Footer";
import ArrowUp from "../components/MainPage/ArrowUp/ArrouwUp";
import HomeSchool from "./HomeSchool";

const MainPage = () => {
    return(
        <>
            <Header/>
            <Hero/>
            <Montessori/>
            <AboutUs/>
            <Edu/>
            <HomeSchool/>
            {/* <Niebieska/> */}
            <Metoda/>
            {/* <Fundacja/> */}
            <Contact/>
            <ArrowUp/>
            <Footer/>
        </>
        
    )
}

export default MainPage;