import '../../styles/AboutUs.css';
import { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"


const AboutUs = () => {
    useEffect(()=>{
        AOS.init({duration:700})
  },[]);

    return(
        <section id="aboutus">
             <div className="puzzlecontainer">
            <img alt="" src="\images\MainPage\puzzle-green-big.png" className="puzzle"></img>
             </div>
        <div className="title"data-aos="fade-up">
        O NAS     
        </div> 
        <div className="description"data-aos="fade-up">
        Twórczynią Projektu Montessori jest Sylwia Smyczek, kreatywna edukatorka z 
        wieloletnim doświadczeniem zarówno w zakresie edukacji tradycyjnej, jak i alternatywnej.
        </div> 
        <div className='line'data-aos="fade-up"></div>
        <div className='aos-div' data-aos = "fade-up">
        <div className='cont'>
            <div className='imgs'>
                <img alt="Sylwia Smyczek" className='photo' src = "\images\MainPage\sylwia.jpg"></img>
                
            </div>
            
            <div className='text-right'>
            <p>
            🎓 Ponad dwudziestoletnie doświadczenie w pracy z dziećmi i młodzieżą w szkołach państwowych i prywatnych.
            <br/><br/>
            📚 Czynny nauczyciel i egzaminator egzaminu maturalnego i ósmoklasisty oraz egzaminator w Edukacji Domowej.
            <br/><br/>
            🌈 Wiedziona ciągłą potrzebą działań twórczych i realizowania własnej wizji nauczania dzieci w sposób aktywny.
            <br/><br/>
            💡 Inspirowana licznymi wizytami w ramach projektu Erasmus+ w szkołach całej Europy, zwłaszcza systemem skandynawskim.
            <br/><br/>
            🎓 Ukończona filologia angielska oraz studia podyplomowe z zarządzania oświatą, zarządzania zasobami ludzkimi oraz w dziedzinie edukacji i terapii osób w spektrum autyzmu oraz oligofrenopedagogiki.
            <br/><br/>
            🌍 W kręgu zainteresowań podróże oraz zagadnienia związane z neurodydaktyką, filozofią Montessori i inteligencjami wielorakimi.
            </p>
            <br/>
            <p style={{textAlign: 'center'}}>
                „Każdy człowiek jest inny i za wszelką cenę powinien bronić swego prawa do indywidualności.”
                Paulo Coelho, Zwycięzca jest sam
            </p>
            </div>
        </div>
        </div>
        
        </section>
    )
}

export default AboutUs;