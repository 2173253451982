export const FaqQuestionFive = () => {
    return(
        <>
            Kiedy składasz dokumenty?
        </>
    )
}

export const FaqAnswerFive = () => {
    return (
        <>
            <p>
            W dowolnym momencie roku szkolnego lub przed rozpoczęciem danego roku szkolnego.
            </p>
        </>
    )
}

export const FaqQuestionFour = () => {
    return(
        <>
            Jakie dokumenty musisz przygotować?
        </>
    )
}

export const FaqAnswerFour = () => {
    return (
        <>
            <p>
                1. Wniosek o zezwolenie na edukację domową. Nie ma jednego wzoru takiego wniosku. Zapytaj w szkole, czy ma wzór. Jeśli nie – napisz wniosek samodzielnie.
            </p>
            <p>
                2. Oświadczenie − napisz w nim, że zapewnisz dziecku warunki, które umożliwią mu realizację podstawy programowej na tym etapie kształcenia; napisz je samodzielnie.
            </p>
            <p>
                3. Zobowiązanie, w którym napiszesz, że twoje dziecko będzie przystępowało do rocznych egzaminów klasyfikacyjnych – napisz je samodzielnie.
            </p>
            <p>
                4. Oryginał świadectwa z ostatniej klasy.
            </p>
        </>
    )
}

export const FaqQuestionOne = () => {
    return(
        <>
            Czym jest edukacja domowa?
        </>
    )
}

export const FaqAnswerOne = () => {
    return (
        <>
            <p>
            Każde dziecko, które ma od 6 do 18 lat, musi się uczyć.
            Spełnia wtedy obowiązek szkolny i nauki.
            Dyrektor szkoły może zgodzić się, żeby dziecko uczyło się w domu.
            Wniosek należy złożyć do dyrektora wybranej szkoły, w której dziecko ma realizować obowiązek szkolny.
            </p>

            <p>
            Wtedy nauczycielami dziecka mogą być:
            <ul>
                <li>rodzice,</li>
                <li>opiekunowie prawni,</li>
                <li>inne osoby, które wskażą rodzice albo opiekunowie.</li>
            </ul>
            </p>

            <p>
            Takie osoby nie muszą kończyć specjalnych studiów ani szkoleń.
            </p>

            <p>
            Dyrektor szkoły może się zgodzić na edukację domową w danym roku szkolnym i wskazać w decyzji etap edukacyjny. Są trzy etapy:
            <ul>
                <li>I etap – I, II i III klasa szkoły podstawowej,</li>
                <li>II etap – IV–VIII klasa szkoły podstawowej,</li>
                <li>III etap – szkoła ponadpodstawowa.</li>
            </ul>
            </p>
        </>
    )
}

export const FaqQuestionSeven = () => {
    return(
        <>
            Czy dyrektor może cofnąć zezwolenie?
        </>
    )
}

export const FaqAnswerSeven = () => {
    return (
        <>
            <p>
                Tak, jeśli:
            </p>
            <ul>
                <li>
                    uznasz, że twoje dziecko powinno uczyć się w szkole – w takiej sytuacji złóż wniosek o cofnięcie zgody na edukację domową do dyrektora, który wydał zezwolenie,
                </li>
                <li>
                    twoje dziecko nie przystąpi do rocznych egzaminów kwalifikacyjnych i nie usprawiedliwisz jego nieobecności albo dyrektor nie uzna twojego usprawiedliwienia,
                </li>
                <li>
                    twoje dziecko nie zda rocznych egzaminów kwalifikacyjnych,
                </li>
                <li>
                    okaże się, że zezwolenie na edukację domową jest nieważne.
                </li>
            </ul>
        </>
    )
}

export const FaqQuestionSix = () => {
    return(
        <>
            Jak oceniane jest dziecko?
        </>
    )
}

export const FaqAnswerSix = () => {
    return (
        <>
            <p>
            Na podstawie rocznych egzaminów klasyfikacyjnych. 
            Zakres egzaminów ustala dyrektor, który wydał zezwolenie na edukację domową. 
            Po zdanych egzaminach twoje dziecko dostanie świadectwo ukończenia danej klasy. 
            Na świadectwie nie będzie oceny z zachowania. Nie będzie też ocen z muzyki, 
            plastyki, techniki i wychowania fizycznego.
            </p>
        </>
    )
}

export const FaqQuestionThree = () => {
    return(
        <>
            Jak zapisać się do szkoły?
        </>
    )
}

export const FaqAnswerThree = () => {
    return (
        <>
            <p>
            Aby zapisać ucznia do szkoły rodzice albo opiekunowie prawni powinni wypełnić i złożyć komplet dokumentów rekrutacyjnych dostępnych w szkole. 
            Następnie należy oczekiwać na decyzję Dyrektora Szkoły zezwalającą na realizację obowiązku szkolnego w formie edukacji domowej.
            Dyrektor wyda decyzję do 30 dni kalendarzowych od dnia, w którym złożysz wszystkie dokumenty. 
            </p>
        </>
    )
}

export const FaqQuestionTwo = () => {
    return(
        <>
            Ile kosztuje edukacja domowa?
        </>
    )
}

export const FaqAnswerTwo = () => {
    return (
        <>
            <p>
            Nauka w trybie edukacji domowej jest <b>bezpłatna</b>. 
            Płatne są warsztaty i zajęcia dodatkowe, które są nieobowiązkowe.
            </p>
        </>
    )
}

export const FaqQuestionEight = () => {
    return(
        <>
            Czy w roku szkolnym można zrealizować podstawę programową z dwóch klas?
        </>
    )
}

export const FaqAnswerEight = () => {
    return (
        <>
            <p>
            W ciągu jednego roku szkolnego możliwa jest realizacja przez ucznia edukacji 
            domowej podstawy programowej z dwóch klas, ale tylko w klasach I i II szkoły 
            podstawowej.
            </p>
            <p>
            Uczniowie klas od IV do VIII szkoły podstawowej oraz uczniowie liceów mogą 
            realizować w jednym roku szkolnym podstawę programową z dwóch lub więcej klas 
            tylko w trybie stacjonarnym, po uzyskaniu zgody na indywidualny tok nauki.
            </p>
        </>
    )
}

export const FaqQuestionNine = () => {
    return(
        <>
            Czy jest możliwość korzystania z pomocy nauczyciela?
        </>
    )
}

export const FaqAnswerNine = () => {
    return (
        <>
            <p>
                Przepisy prawa oświatowego uprawniają ucznia edukacji domowej  do skorzystania z 
                konsultacji dotyczących przygotowanie do rocznych egzaminów klasyfikacyjnych. 
                Zasady i formy organizacji takich konsultacji określa dyrektor szkoły. 
                Szkoła może również zorganizować zajęcia dodatkowe i/lub zajęcia rewalidacyjne.
            </p>
        </>
    )
}

export const FaqQuestionTen = () => {
    return(
        <>
            Czy można brać udział w konkursach?
        </>
    )
}

export const FaqAnswerTen = () => {
    return (
        <>
            <p>
                Tak, udział w konkursach jest możliwy. Uczeń zainteresowany udziałem w konkursie 
                musi poinformować dyrektora swojej szkoły o zamiarze wzięcia 
                udziału w danym konkursie z odpowiednim wyprzedzeniem umożliwiającym dyrektorowi 
                jego szkoły spełnienie wymagań formalnych stawianych przez organizatora konkursu.
            </p>
        </>
    )
}

export const FaqQuestionEleven = () => {
    return(
        <>
            Kiedy możesz umawiać się na egzaminy?
        </>
    )
}

export const FaqAnswerEleven = () => {
    return (
        <>
            <p>
                Na indywidualne egzaminy klasyfikacyjne uczeń może umówić się od pierwszego dnia 
                roku szkolnego do dnia wskazanego w planie roku szkolnego jako ostatni dzień 
                zdawania egzaminów klasyfikacyjnych.
            </p>
        </>
    )
}

export const FaqQuestionTwelve = () => {
    return(
        <>
            Co zrobić, jeśli uczeń otrzyma ocenę niedostateczną z egzaminu końcoworocznego?
        </>
    )
}

export const FaqAnswerTwelve = () => {
    return (
        <>
            <p>
                Uczeń edukacji domowej, który na egzaminie klasyfikacyjnym otrzymał ocenę 
                negatywną z jednego lub dwóch przedmiotów, może poprawić ocenę w trakcie 
                sesji poprawkowej w sierpniu.
            </p>
        </>
    )
}

export const FaqQuestionThirteen = () => {
    return(
        <>
            Lista obowiązkowych rocznych egzaminów klasyfikacyjnych
        </>
    )
}

export const FaqAnswerThirteen = () => {
    return(
        <>
            <h2>Szkoła podstawowa, klasy I-III:</h2>
            <p>
            klasa I
            <ul>
                <li>nauczanie zintegrowane</li>
                <li>język obcy nowożytny</li>
            </ul>
            </p>
            <p>
            klasa II
            <ul>
                <li>nauczanie zintegrowane</li>
                <li>język obcy nowożytny</li>
            </ul>
            </p>
            <p>
            klasa III
            <ul>
                <li>nauczanie zintegrowane</li>
                <li>język obcy nowożytny</li>
            </ul>
            </p>

            <h2>Szkoła podstawowa, klasy IV-VIII:</h2>
            <p>
            klasa IV
            <ul>
                <li>j. polski</li>
                <li>język obcy nowożytny</li>
                <li>historia</li>
                <li>matematyka</li>
                <li>przyroda</li>
                <li>informatyka</li>
            </ul>
            </p>
            <p>
            klasa V
            <ul>
                <li>j. polski</li>
                <li>język obcy nowożytny</li>
                <li>historia</li>
                <li>matematyka</li>
                <li>geografia</li>
                <li>biologia</li>
                <li>informatyka</li>
            </ul>
            </p>
            <p>
            klasa VI
            <ul>
                <li>j. polski</li>
                <li>język obcy nowożytny</li>
                <li>historia</li>
                <li>matematyka</li>
                <li>geografia</li>
                <li>biologia</li>
                <li>informatyka</li>
            </ul>
            </p>
            <p>
            klasa VII
            <ul>
                <li>j. polski</li>
                <li>język obcy nowożytny</li>
                <li>drugi język obcy nowożytny</li>
                <li>historia</li>
                <li>matematyka</li>
                <li>geografia</li>
                <li>biologia</li>
                <li>informatyka</li>
                <li>chemia</li>
                <li>fizyka</li>
            </ul>
            </p>
            <p>
            klasa VIII
            <ul>
                <li>j. polski</li>
                <li>język obcy nowożytny</li>
                <li>drugi język obcy nowożytny</li>
                <li>historia</li>
                <li>matematyka</li>
                <li>geografia</li>
                <li>biologia</li>
                <li>informatyka</li>
                <li>chemia</li>
                <li>fizyka</li>
                <li>wiedza o społeczeństwie</li>
                <li>edukacja dla bezpieczeństwa</li>
            </ul>
            </p>
        </>
    )
}

export const FaqQuestionFourteen = () => {
    return(
        <>
            Jak wyglądają egzaminy?
        </>
    )
}

export const FaqAnswerFourteen = () => {
    return(
        <>
            <p>
                Uczniowie w edukacji domowej mają obowiązek zdać egzaminy końcoworoczne ze 
                wszystkich przedmiotów, które obowiązują w danej klasie.
                Egzamin składa się z części pisemnej (ok. 45 min.) oraz ustnej. 
                Rozmowa z nauczycielem trwa około 15 minut.
            </p>
        </>
    )
}