const setAStyle = (isTablet: boolean, isPhone: boolean) => {
  if(isTablet || isPhone){
    return {
      fontSize: "2.5rem",
      maxWidth: "100%",
      padding: "20px"
    }
  }
}

const NavList = (props: any) => {

  // Hiding the menu on mobile devices (showing hamburger to open it)
  const classNames = (props.isTablet || props.isPhone)? "main-menu tablet": "main-menu";
  const navButtons = props.navButtons;
  const listItems = navButtons.map((buttons: any) =>
      <li key={buttons[0]} className="nmli">
        <a 
          href={buttons[1]} 
          data-after={buttons[0]} 
          className="nm"
          onClick={() => {
            (props.isTablet || props.isPhone) && props.toggleActive()
            document.querySelector(".main-menu")?.classList.remove("active")
            document.querySelector(".hamburger")?.classList.remove("active")
          }}
          style={setAStyle(props.isTablet, props.isPhone)}
          >
          {buttons[0]}
        </a>
      </li>
  );
  return (
    <ul className={props.show? classNames + " active": classNames}>
      {listItems}
    </ul>
  );
}

export default NavList