import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Faq from './pages/Faq';
import WelcomePage from './pages/WelcomePage';
import Therapies from './pages/Therapies';
import AdditionalOffer from './pages/AdditionalOffer/AdditionalOffer';

const Main = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/main" element={<MainPage />} />
                <Route path="/therapies" element={<Therapies />} />
                <Route path="/additional-offer/:id" element={<AdditionalOffer />} />
                <Route path="*" element={<WelcomePage />} />
            </Routes>
        </Router>
        
    );
}

export default Main;