import FaqQuestion from "../components/Faq/FaqQuestion";
import Footer from "../components/MainPage/Footer";
import Header from "../components/MainPage/Header";
import "../styles/faq.css"
import { 
    FaqQuestionOne, 
    FaqAnswerOne,
    FaqQuestionTwo,
    FaqAnswerTwo,
    FaqQuestionThree,
    FaqAnswerThree,
    FaqQuestionFour,
    FaqAnswerFour,
    FaqQuestionFive,
    FaqAnswerFive,
    FaqQuestionSix,
    FaqAnswerSix,
    FaqQuestionSeven,
    FaqAnswerSeven,
    FaqQuestionEight,
    FaqAnswerEight,
    FaqQuestionNine,
    FaqAnswerNine,
    FaqQuestionTen,
    FaqAnswerTen,
    FaqQuestionEleven,
    FaqAnswerEleven,
    FaqQuestionTwelve,
    FaqAnswerTwelve,
    FaqQuestionThirteen,
    FaqAnswerThirteen,
    FaqQuestionFourteen,
    FaqAnswerFourteen,
} from "../components/Faq/FaqAllQuestions";
import ArrowUp from "../components/MainPage/ArrowUp/ArrouwUp";

const Faq = () => {
    return (
        <>
            <Header/>
            <div id="faq">
                <h1>Masz pytania?</h1>
                <h2>Mamy odpowiedzi!</h2>
                <div className="description">
                    <h3>
                        Poniżej znajdziesz odpowiedzi na najczęściej zadawane 
                        pytania związane z edukacją domową i naszą działalnością.
                        W przypadku braku odpowiedzi na Twoje pytanie, <span/>
                        Skontaktuj się z nami - <a href = "mailto: biuro@akademia-champion.pl">biuro@akademia-champion.pl</a>.
                        Lub sprawdź nasze media społecznościowe: <span/>
                        <a href="https://www.facebook.com/profile.php?id=100095196601600" target="_blank" rel="noreferrer">Facebook</a> lub <span/>
                        <a href="https://instagram.com/edukacjaalternatywna?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noreferrer">Instagram</a>.
                    </h3>
                </div>
                
                <div className="img">
                    <img src="/images/Faq/7879.jpg" alt=""></img>
                    <div className="annotation">
                        <a href="http://www.freepik.com" target="_blank" rel="noreferrer">Designed by pch.vector / Freepik</a>
                    </div>
                </div>
                <div className="questions">
                    <div className="left-questions">
                        <FaqQuestion 
                            question={<FaqQuestionOne/>}
                            answer={<FaqAnswerOne/>}
                        />
                        <FaqQuestion 
                            question={<FaqQuestionTwo/>}
                            answer={<FaqAnswerTwo/>}
                        />
                        <FaqQuestion 
                            question={<FaqQuestionThree/>}
                            answer={<FaqAnswerThree/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionFour/>}
                            answer={<FaqAnswerFour/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionFive/>}
                            answer={<FaqAnswerFive/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionSix/>}
                            answer={<FaqAnswerSix/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionSeven/>}
                            answer={<FaqAnswerSeven/>}
                        />
                    </div>
                    <div className="right-questions">
                        <FaqQuestion
                            question={<FaqQuestionEight/>}
                            answer={<FaqAnswerEight/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionNine/>}
                            answer={<FaqAnswerNine/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionTen/>}
                            answer={<FaqAnswerTen/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionEleven/>}
                            answer={<FaqAnswerEleven/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionTwelve/>}
                            answer={<FaqAnswerTwelve/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionThirteen/>}
                            answer={<FaqAnswerThirteen/>}
                        />
                        <FaqQuestion
                            question={<FaqQuestionFourteen/>}
                            answer={<FaqAnswerFourteen/>}
                        />
                    </div>
                </div>
                
                <div className="bottom">
                    <h2>Chcesz dowiedzieć się więcej?</h2>
                    <div className="description">
                        <h3>
                            Zapraszamy do kontaktu z nami. - <a href = "mailto: biuro@akademia-champion.pl">biuro@akademia-champion.pl</a>.
                        </h3>
                    </div>
                </div>
                <p>
                    Podstawa prawna
                    <ul>
                        <li>Ustawa z dnia 14 grudnia 2016 r. – Prawo oświatowe (Dz. U. 2021 poz. 1082 ze zm.)</li>
                        <li>Ustawa z dnia 14 czerwca 1960 r. Kodeks postępowania administracyjnego (Dz. U. 2022 poz. 2000 ze zm.)</li>
                    </ul>
                </p>
                
            </div>
            <ArrowUp/>
            <Footer/>
        </>
        
    );
}

export default Faq;