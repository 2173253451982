import { useState } from "react";

type FaqQuestionProps = {
    question: JSX.Element | string;
    answer: JSX.Element | string;
}

const FaqQuestion = ({ question, answer }: FaqQuestionProps) => {
    const [isAnswerVisible, setIsAnswerVisible] = useState(false);
    
    const toggleAnswer = () => {
        setIsAnswerVisible(!isAnswerVisible);
    };
    
    return (
        <div className="faq_question">
            <div className="faq_question_header" onClick={toggleAnswer}>
                <div className="faq_question_header_question">{question}</div>
                <div className="faq_question_header_icon">
                </div>
            </div>
            {isAnswerVisible && (
                <div className="faq_question_answer">{answer}</div>
            )}
        </div>
    );
}

export default FaqQuestion;