import "../../styles/Hero.css"
import Puzzle from ".//Hero/Puzzle"

const Hero = () => {
    return (
        <section id="hero">
            <div className="hero">
                <div className="top-section">   
                <div className="hero-photo">
                    <a 
                    className="annotation" 
                    href="https://www.freepik.com/free-photo/classmates-working-together_863223.htm#query=children%20learning&position=9&from_view=search&track=ais"
                    target="_blank"
                    rel="noreferrer">
                        Image by pressfoto on Freepik
                    </a> 
                    <img className="heroPhoto" src="/images/MainPage/d.jpg" alt="dzieci w szkole"/>
                </div>
                
                <div className="hero-description">
                    <h1 className="hero-description-text">"Pozwól mi zrobić to samemu." ~ M.Montessori</h1>   
                </div>
                </div>
                <div className="fundaments">
                    <Puzzle image="/images/MainPage/puzzle-green.png" rotation="120" text="Samodzielność"/>
                    <Puzzle image="/images/MainPage/puzzle-blue.png" rotation="45" text="Indywidualne podejście"/>
                    <Puzzle image="/images/MainPage/puzzle-yellow.png" rotation="10" text="Swoboda wyboru"/>
                    <Puzzle image="/images/MainPage/puzzle-brown.png" rotation="-10" text="Zasada porządku"/>
                    
                </div>
            </div>
        </section>
    )
}

export default Hero