const Carousel = () => {
    return (
  <div id="carousel" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
    <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carousel" data-bs-slide-to="3" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="8000">
    <img src="/images/MainPage/two-teenagers.jpg" className="d-block w-100 carousel" alt=""/>
    </div>
    <div className="carousel-item" data-bs-interval="8000">
    <img src="/images/MainPage/children-table.jpg" className="d-block w-100 carousel" alt=""/>
    </div>
    <div className="carousel-item" data-bs-interval="8000">
    <img src="/images/MainPage/children-outside.jpg" className="d-block w-100 carousel" alt=""/>
    </div>
    <div className="carousel-item" data-bs-interval="8000">
    <img src="/images/MainPage/children-desk.jpg" className="d-block w-100 carousel" alt=""/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
    <span className="carousel-control-prev-icon arrow" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
    <span className="carousel-control-next-icon arrow" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    );
};

export default Carousel;