import React from 'react';
import  "../Common/Popup.css"
import ReactDOM from 'react-dom';
import './Popup.css';

interface PopupProps {
  popupText: string;
  isOpen: boolean;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ popupText, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content">
        <div className='popup-text' >{popupText}</div>
      </div>
    </div>,
    document.body
  );
};

export default Popup;

