const Puzzle = (props: any) => {
    return (
        <div className="fundament">
            <div className="puzzle" style={{'transform': `rotate(${props.rotation}deg)`}}>
                <img src={props.image} alt="puzzle"/>
            </div>
            <h1>{props.text}</h1>
        </div>
        
    )
}

export default Puzzle;