import Footer from '../../components/MainPage/Footer';
import Header from '../../components/AdditionalOffer/Header';
import '../../styles/AdditionalOffer.css'
import { useParams } from 'react-router-dom';
import { SubsiteAdditionalOffer, SubsiteAdditionalOfferHeader } from './AdditionalOffer.types';
import { useEffect, useState } from 'react';

const AdditionalOffer = () => {
    const [page, setPage] = useState<string>('');

    const subsiteId = useParams<{id: SubsiteAdditionalOffer}>().id;

    useEffect(() => {
        const pageUrl = process.env.PUBLIC_URL + `/addOfferHtml/${subsiteId}.html`;
        fetch(pageUrl).then(response => response.text()).then(text => setPage(text));
    }, [subsiteId]);

    if (!subsiteId) return <h1>WRONG SUBSITE</h1>;

    return (
        <>
        <Header />
        <section id="additionalOffer">
            <div className="additionalOffer">
                <div className="top-section">   
                    <div className="additionalOffer-photo">
                        <a 
                        className="annotation" 
                        href="https://www.freepik.com/free-photo/kids-participating-treasure-hunt_16621675.htm#fromView=search&page=1&position=9&uuid=b611f701-22ea-417a-874d-3261d91b6a01"
                        target="_blank"
                        rel="noreferrer"> 
                            Image by rawpixel.com on Freepik
                        </a> 
                        <img className="additionalOfferPhoto" src="/images/AdditionalOffer/additional-offer.jpg" alt="Zajęcia robotyki i programowania"/>
                    </div>
                    
                    <div className="additionalOffer-description">
                        <h1 className="additionalOffer-description-text">{SubsiteAdditionalOfferHeader[subsiteId]}</h1>   
                    </div>
                </div>
            </div>
        </section>
        <div >
            <div className='page-wrapper' dangerouslySetInnerHTML={{__html: page}}/>
        </div>
        <Footer />
        </>
        
    )
}

export default AdditionalOffer;