import { MantineProvider } from '@mantine/core';
import './App.css';
import Main from './Main';
import '@mantine/core/styles.css';

function App() {
  return (
    <div className="App">
      <MantineProvider>
        <Main/>
      </MantineProvider>
    </div>
  );
}

export default App;
