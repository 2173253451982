import { useEffect } from "react"
import "../../styles/Montessori.css"
import Carousel from "../Common/Carousel"
import AOS from "aos"
import "aos/dist/aos.css"






const Montessori = () => {
    useEffect(()=>{
          AOS.init({duration:700})
    },[]);

    return(
        <section id="montessori"  >  
            
            <div className="puzzlecontainer">
            <img src="\images\MainPage\puzzle-blue-big.png" className="puzzle" alt=""></img>
             </div>
            <div className="title" data-aos="fade-up">
            PROJEKT MONTESSORI
            </div> 
           
            <div >
            <p className="quote" data-aos="fade-up">"Nie mów im, jak to zrobić. Pokaż im, jak to zrobić i nie mów ani słowa. 
            Jeśli im powiesz, będą patrzeć,<br></br> jak poruszają się twoje usta.  Jeśli 
            im pokażesz, będą chcieli to zrobić sami." ~ M. Montessori
            </p>    
            </div> 
            <div className="line" data-aos="fade-up">
            </div>
            
<div className="container"data-aos="fade-up">
    <div className="column-left">
                <div className="line2"></div>
    </div>
    <div className="column-middle">
                    <div className="misja">MISJA I WIZJA</div>
                    <div className ="descriptionText">
                            Misją Projektu Montessori jest przygotowanie dzieci do życia, z uwzględnieniem czterech filarów 
                        <a href="https://dziecisawazne.pl/jaka-jest-roznica-pomiedzy-metoda-montessori-a-tradycyjna-edukacja/" target='blank'> pedagogiki Marii Montessori </a>
                            i 
                        <a href="https://holistic.news/skandynawski-model-edukacji-niezwykla-forma-uczenia-zaradnosci/" target='blank'> modelu skandynawskiego </a> 
                            : indywidualne podejście, swoboda wyboru, zasada porządku oraz samodzielność. 
                        <br/><br/>
                            Tworzymy kompleksową ofertę edukacyjną na miarę XXI wieku. Nasza wizja łączy tradycyjne i nowoczesne rozwiązania edukacyjne. 
                            Dajemy uczniom możliwość wyboru nauki w trybie stacjonarnym w ramach opieki dziennej, 
                            prowadzonej zgodnie z filozofią Montessori lub w 
                        <a href="https://domowa.edu.pl/" target='blank'> edukacji domowej</a> . 
                        <br/><br/>
                            Naszym głównym celem jest praca na rzecz osób w spektrum autyzmu. 
                            Sylwetka absolwenta naszej placówki to osoba samodzielna, znająca swoje mocne strony, potrafiąca dokonywać wyborów oraz 
                            umiejąca odpowiednio postępować w sytuacjach społecznych.
                    </div>

    </div>
    <div className="column-right">
            <div className="carousel">
                <Carousel/>
            </div>
    </div>
</div>


</section>
)
}

export default Montessori