type WelcomePageRowProps = {
    title: string;
    link: string;
    image: string;
    target: string;
}

const WelcomePageRow = (props: WelcomePageRowProps) => {
    const { title, link, image, target } = props;

    return (
        <div className="welcome-page-col">
            <a 
            className="welcome-page-col-link"
            style={{backgroundImage: `url(${image})`}}
            href={link}
            target={target}
            rel="noreferrer"
            >
                <h2>{title}</h2>
            </a>
        </div>
        
    );
}

export default WelcomePageRow;