import WelcomePageRow from "../components/WelcomePage/WelcomePageCol";
import "../styles/WelcomePage.css";

const WelcomePage = () => {
    return(
        
        <div className="welcome-page">
            <div className="welcome-page-header">
                <div className="logos">
                    <img src="/images/MainPage/logo.svg" alt="logo"/>
                    <a href="https://akademia-champion.pl/" target="_blank" rel="noreferrer">
                        <img src="/images/WelcomePage/champion-logo.jpg" alt="logo"/>
                    </a>
                </div>
                
                <h1>Akademia Edukacji i Rozwoju Champion</h1>
                <div></div>
            </div>
            <div className="welcome-page-cols">
                <WelcomePageRow 
                    title="Domowa Szkoła Montessori" 
                    link="/main"
                    image="/images/WelcomePage/home-schooling.jpg"
                    target="_self"
                />
                <WelcomePageRow 
                    title="Kursy polskiego, angielskiego i matematyki"
                    link="https://akademia-champion.pl/"
                    image="/images/WelcomePage/TE.jpg"
                    target="_blank"
                />
                <WelcomePageRow 
                    title="Oferta dodatkowa"
                    link="/additional-offer/artWorkshops"
                    image="/images/WelcomePage/additional-offer.jpg"
                    target="_self"
                />
                <WelcomePageRow 
                    title="Terapie specjalistyczne"
                    link="https://centrum.terapii.com.pl/"
                    image="/images/WelcomePage/psychologist.jpg"
                    target="blank"
                />
            </div>
        </div>
    )
}

export default WelcomePage;