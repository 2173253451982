import "../../styles/Metoda.css";
import { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import { useState } from 'react'
import Popup from "../Common/Popup"





const Metoda = () => {
    useEffect(()=>{
        AOS.init({duration:700})
  },[]);

  const [popupState, setPopupState] = useState<Map<string, boolean>>(new Map());

  const togglePopup = (popupId: string) => {
    const newPopupState = new Map(popupState);
    newPopupState.set(popupId, !newPopupState.get(popupId));
    setPopupState(newPopupState);
  };

  const buttons: { id: string; buttonText: string; popupText: string; }[] = [
    { id: 'button1', buttonText: 'Uczenie się przez działanie', popupText: 'Dzieci zdobywają wiedzę, kompetencje, umiejętności i doświadczenie poprzez własną spontaniczną aktywność; nauczyciele nie narzucają dzieciom działań, ale współpracują w procesie wychowawczym.', },
    { id: 'button2', buttonText: 'Koncentracja', popupText: 'Dzieci uczą się dokładności i systematyczności podczas działania.' },
    { id: 'button3', buttonText: 'Porządek', popupText: 'Dzieci nabywają umiejętności w utrzymywaniu porządku w swoim otoczeniu.' },
    { id: 'button4', buttonText: 'Obserwacja', popupText: 'To podstawowa zasada umożliwiająca dorosłym zapoznanie się ze światem dziecka; wychowawca powinien z baczną uwaga i dużym szacunkiem obserwować dziecko, aby zauważyć ewentualne przeszkody w jego rozwoju; obserwacja daje możliwość bycia przewodnikiem dziecka.' },
    { id: 'button5', buttonText: 'Samodzielność', popupText: 'Dzieci samodzielnie podejmują decyzję dotyczącą miejsca, rodzaju i czasu pracy; decyzja jest indywidualna lub podejmowana razem z partnerem; w ten sposób dzieci kształcą swoje indywidualne zdolności i nabierają doświadczenia w prawdziwej ocenie własnych umiejętności.' },
    { id: 'button6', buttonText: 'Lekcje ciszy', popupText: 'Dzieci nabierają wprawy w umiejętności pracy w ciszy.' },
    { id: 'button7', buttonText: 'Społeczne reguły', popupText: 'Łączenie w grupy dzieci w różnym wieku (zazwyczaj trzy roczniki), co umożliwia wzajemne uczenie się od siebie; wykształcenie świadomości, że nie wolno przeszkadzać, ranić i niszczyć.' },
    { id: 'button8', buttonText: 'Indywidualny tok rozwoju dziecka', popupText: 'Wszystkie dzieci powinny rozwijać się według indywidualnych możliwości i własnego tempa, powinny podejmować tylko te zadania, do których są już przygotowane; każde dziecko zasługuje na życzliwość, uwagę i indywidualną opiekę wychowawcy.' },
  ];
    


 

  
return (
    <section id="metoda">
      <div className="puzzlecontainer">
          <img src="\images\MainPage\puzzle-blue-big.png" className="puzzle" alt=""></img>
        </div>
    <div className="randomDiv"data-aos="fade-up">
        <div className="title">
        <h1 className="title-text">METODA MONTESSORI</h1>
        </div>
        <div className="aosDiv" data-aos = "fade-up">
          <div className="quote">
          „Niech dzieci będą wolne. Zachęcaj ich do biegania na zewnątrz, 
          gdy pada deszcz. Niech zdejmą buty, gdy znajdą kałużę wody." ~ M. Montessori 
          </div>
        </div>
        </div>
        <div className="aosDiv" data-aos="fade-up">
        <div className="line"></div>
        <div className="container">
      {buttons.map(({ id, buttonText, popupText }) => (
        <div key={id} onClick={() => togglePopup(id)}>
          <button  className={`item ${id}`}>

            <div className="button-text">{buttonText}</div>
          </button>
          {popupState.get(id) && (
            <Popup popupText={popupText} isOpen={true} onClose={() => togglePopup(id)} />
          )}
        </div>
      ))}
    </div>
    <div className="comparison">Porównanie edukacji Montessori z edukacją konwencjonalną</div>
    <div className="btn-container">
        <a 
        href="https://www.radamontessori.pl/nauczyciele/porownanie-edukacji-montessori-z-edukacja-konwencjonalna/" 
        target="_blank" 
        rel="noreferrer"
        >
          <button type="button" className="comparison-button">PORÓWNANIE</button>
        </a>
    </div>
    </div>
      

        
         
        
    </section>
    );
}
export default Metoda;

