export enum SubsiteAdditionalOffer {
    artWorkshops = 'artWorkshops',
    boardGames = 'boardGames',
    thematicParties = 'thematicParties',
    cyclicEvents = 'cyclicEvents',
    artLanguageSemiColonies = 'artLanguageSemiColonies',
    oneDayTrips = 'oneDayTrips',
    thematicBirthdayParties = 'thematicBirthdayParties',
    practicalWorkshops = 'practicalWorkshops',
    meetingsWorkshops = 'meetingsWorkshops',
}

export enum SubsiteAdditionalOfferHeader {
    artWorkshops = 'Warsztaty artystyczne',
    boardGames = 'Wieczory planszówek',
    thematicParties = 'Imprezy tematyczne',
    cyclicEvents = 'Dla młodzieży',
    artLanguageSemiColonies = 'Półkolonie',
    oneDayTrips = 'Wycieczki',
    thematicBirthdayParties = 'Urodzinki Tematyczne',
    practicalWorkshops = 'Szkolenia',
    meetingsWorkshops = 'Spotkania dla kobiet',
}