import Footer from '../components/MainPage/Footer';
import Header from '../components/Therapies/Header';
import Therapy from '../components/Therapies/Therapy';
import '../styles/Therapies.css'

const Therapies = () => {
    return (
        <>
        <Header />
        <section id="therapies">
            <div className="therapies">
                <div className="top-section">   
                    <div className="therapies-photo">
                        <a 
                        className="annotation" 
                        href="https://www.freepik.com/free-photo/classmates-working-together_863223.htm#query=children%20learning&position=9&from_view=search&track=ais"
                        target="_blank"
                        rel="noreferrer">
                            Image by pressfoto on Freepik
                        </a> 
                        <img className="therapiesPhoto" src="/images/Therapies/helping.jpg" alt="Profesjonalne terapie"/>
                    </div>
                    
                    <div className="therapies-description">
                        <h1 className="therapies-description-text">Terapie specjalistyczne</h1>   
                    </div>
                </div>
            </div>
        </section>
        <Therapy 
        id="psychologist"
        title='Psycholog' 
        description={
            <>
                <h2>Pani Joanna Wach, PSYCHOLOG z doświadczeniem</h2>
                <p>
                    Od wielu lat pracuje zarówno z dziećmi, jak i z dorosłymi. Specjalizuje się
                    w pracy z osobami niepełnosprawnymi, w spektrum autyzmu i z innymi
                    zaburzeniami.
                </p>
                <p>
                    Prowadzi TRENINGI UMIEJĘTNOŚCI SPOŁECZNYCH dla dzieci i młodzieży. Organizuje
                    GRUPY WSPARCIA dla rodziców dzieci z trudnościami rozwojowymi.
                </p>
                <p>
                    Prowadzi grupy wsparcia dla MAM dzieci w wieku 0-12 miesięcy. Organizuje
                    warsztaty i szkolenia dla PERSONELU oraz RODZICÓW.
                </p>
                <p>
                    Prowadzi inne grupowe oraz INDYWIDUALNE zajęcia dla dzieci szkolnych,
                    zgodnie z zapotrzebowaniem.
                </p>
            </>
        }
        image='/images/Therapies/psychologist.jpg'
        />
        <Therapy 
        id="logopedist"
        title='Logopeda' 
        description={
            <>
                <h2>Pani Izabela Porwoł, specjalistka w zakresie LOGOPEDII i NEUROLOGOPEDII</h2>
                <p>
                    Od wielu lat pracuje zarówno z dziećmi, jak i z dorosłymi.
                    Specjalizuje się w pracy z osobami niepełnosprawnymi, w spektrum autyzmu i z innymi zaburzeniami.
                </p>
                <p>
                    Prowadzi trening karmienia oraz trening komunikacji alternatywnej. Jest także prelegentką wielu wydarzeń logopedycznych.
                </p>
            </>
        }
        image='/images/Therapies/logopedist.jpg'
        reverse={true}
        />
        <Footer />
        </>
        
    )
}

export default Therapies;