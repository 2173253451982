import "../../styles/Edu.css";
import { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"


const Edu = () => {
    useEffect(()=>{
        AOS.init({duration:700})
  },[]);
    return (
        <section id="edu">
            <div className="randomDiv"data-aos="fade-up">
            <div className="title">
                <div className="edudom">
                    EDUKACJA DOMOWA
                </div>
            </div>
            <div className="quote">
            „Wychowanie, nawet małego dziecka, nie ma więc na celu przygotowania go do szkoły, ale do życia.” ~ M. Montessori
            </div>
            <div className="line"></div>
            </div>
            <div className="listcontainer"data-aos="fade-up">
    
                <ul className="list">
                    <li className="listline">
                       <img alt="" src = "\images\MainPage\puzzle-green-small.png"className="puzzlegreen"></img>
                       <div className = "linetext">Rodzic jest nauczycielem dla swojego dziecka, starsi uczniowie uczą się sami</div></li>
                    <li className="listline">
                        <img alt="" src = "\images\MainPage\puzzle-yellow-small.png"className="puzzleyellow"></img>
                        <div className="linetext"> Uczniowie są zapisani do konkretnej szkoły, ale nie uczęszczają na zajęcia lekcyjne</div>
                   </li>
                    <li className="listline">
                        <img alt="" src = "\images\MainPage\puzzle-blue-small.png"className="puzzleblue"></img> 
                        <div className="linetext">Dzieci i młodzież uczą się w domu, na zajęciach pozaszkolnych lub korzystają z <b>opieki dziennej</b> (spędzają czas w sposób twórczy i kreatywny)</div>
                    </li>
                    <li className="listline">
                        <img alt="" src = "\images\MainPage\puzzle-red-small.png"className="puzzlered"></img>
                        <div className="linetext">Raz w roku zdają egzaminy z każdego przedmiotu i otrzymują świadectwo szkolne</div>
                    </li>
                    <li className="listline">
                        <img alt="" src = "\images\MainPage\puzzle-green-small.png"className="puzzlegreen"></img>
                        <div className="linetext"><a href="https://domowa.edu.pl/">Edukacja domowa</a> jest dla każdego, kto pragnie wolności w edukacji oraz czasu na rozwijanie swoich pasji i zainteresowań</div>
                    </li>
                    <li className="listline">
                        <img alt="" src = "\images\MainPage\puzzle-yellow-small.png"className="puzzleyellow"></img>
                        <div className="linetext">Na edukację domową można zapisać się w dowolnym momencie roku szkolnego</div>
                    </li>
                </ul>
            </div>
            <div className="random-container" data-aos="fade-up">
            <div className="line2"></div>
            <div className="alternative">Edukacja domowa jest alternatywą do tradycyjnej szkoły, z której skorzystać może każdy uczeń w Polsce.</div>
            <div className="more">Po więcej informacji, sprawdź najczęściej zadawane pytania:</div>
            <div className="form">
                <a href="/faq">
                    <button type="button" className="faq">FAQ</button>
                </a>
            </div>
      </div>
            
        </section>
    )
}

export default Edu;