import "../../styles/Contact.css"
import "aos/dist/aos.css"

const Contact = () => {
    return(
        <section id="contact">
          
          <div className="title" data-aos = "fade-up">
             <h1 className="title-text">DANE KONTAKTOWE</h1>
            </div>
                <div className="contact container" data-aos = "fade-up">
                <div className="text-container">
                    <p className="description-text2">
                        W razie jakichkolwiek pytań lub wątpliwości prosimy o zapoznanie się z najczęściej zadawanymi pytaniami
                        <a href="/faq" className="link"> FAQ</a> lub o kontakt z nami.
                    </p>
                </div>
                <div className="contact-items">
                    <div className="contact-item">
                        <div className="icon"><img src="/images/MainPage/map.png" alt="adres"/></div>
                        <div className="contact-info">
                            <h1>Adres</h1>
                            <h2>Rybnik - Nowiny, ul. Kominka 21c</h2>
                        </div>
                    </div>
                    <div className="contact-item">
                        <div className="icon"><img src="/images/MainPage/phone.png" alt="telefon"/></div>
                        <div className="contact-info">
                            <h1>Telefon</h1>
                            <h2>+48 693 262 615</h2>
                        </div>
                    </div>
                    <div className="contact-item">
                        <div className="icon"><img src="/images/MainPage/mail.png" alt="e-mail"/></div>
                        <div className="contact-info">
                            <h1>E-mail</h1>
                            <h2>biuro@akademia-champion.pl</h2>
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact