import "../../styles/Footer.css";

const Footer = () => {
    return(
        <section id="footer">
            <div className="footer container">
                <div className="brand"><h1>AKADEMIA EDUKACJI I ROZWOJU CHAMPION</h1></div>
                <h2>Miejsce spotkań i integracji.</h2>
                <div className="social-icon">
                    <div className="social-item">
                        <a href="https://www.facebook.com/centrumedukacjialternatywnej" target="_blank" rel="noreferrer"><img src="/images/MainPage/fb.png" alt="facebook"/></a>
                    </div>
                    <div className="social-item">
                        <a href="https://instagram.com/edukacjaalternatywna?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noreferrer"><img src="/images/MainPage/ig.png" alt="instagram"/></a>
                    </div>
                </div>
                <p>Copyright © 2024 Filip Smyczek. All rights reserved</p>
            </div>
        </section>
    )
}

export default Footer