import { useCallback, useEffect, useState } from "react"
import "../../styles/Header.css"
import NavList from "./Header/NavList"
import { useMediaQuery } from 'react-responsive'

// If you want to add a new button, add it to the array below [button name, button link]
const buttons: [string, string][] = [
    ["Projekt Montessori", "/main#montessori"], 
    ["O Nas", "/main#aboutus"], 
    ["Edukacja Domowa", "/main#edu"], 
    ["Domowa szkoła", "/main#home-school"],
    ["Metoda Montessori", "/main#metoda"],
    ["Kontakt", "/main#contact"]
]

const Header = () => {

    const [isActive, setActive] = useState(false);
    const [y, setY] = useState(window.scrollY);
    const [isScrollingUp, setIsScrollingUp] = useState(true);

    const handleNavigation = useCallback((e: any) => {
        const window = e.currentTarget;
        if (y > window.scrollY) {
            if (isScrollingUp !== true) {
                setIsScrollingUp(true);
            }
        } 
        else{
            if (isScrollingUp !== false) {
                setIsScrollingUp(false);
            }
        }
        setY(window.scrollY);
        }, [y, isScrollingUp]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);
    

    const toggleClass = () => {
        const classesMenu = document.querySelector(".main-menu")?.classList;
        const classesHamburger = document.querySelector(".hamburger")?.classList;
        if (classesMenu?.contains("active")){
            classesMenu.remove("active");
            classesHamburger?.remove("active");
            setActive(false);
        }
        else {
            classesMenu?.add("active");
            classesHamburger?.add("active");
            setActive(true);
        }
    };

    const isTablet = useMediaQuery({
        query: '(max-width: 1200px)'
    })
    const isPhone = useMediaQuery({
        query: '(max-width: 600px)'
    })

    


    return (
        <section 
        id="header" 
        style={
            (isScrollingUp) || (isActive)
            ? {}
            : {transform: 'translate(0, -100%)'}
            }>
            <div className="header">
                <div className="nav-bar">
                    <div className="logo">
                        <a href="/"><img src="/images/MainPage/logo.svg" alt="logo"/></a>
                    </div>
                    {isTablet? 
                        <div 
                            className="hamburger"
                            onClick={toggleClass}
                        >
                            <div className="bar"></div>
                        </div>: null
                    }
                    <div className="nav-list">
                        <NavList 
                            navButtons={buttons}
                            isPhone={isPhone} 
                            isTablet={isTablet}
                            show={isActive}
                            toggleActive={() => setActive(!isActive)}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header