import '../../styles/Therapy.css'

type TherapyProps = {
    title: string;
    image: string;
    description: JSX.Element;
    reverse?: boolean;
    id: string;
}

const Therapy = (props: TherapyProps) => {
    return(
        <div>
            <div id={props.id} className='therapy'>
                <div className={`top-section ${props.reverse? 'rev': ''}`}>
                    <div className="therapy-photo">
                        <img className="therapyPhoto" src={props.image} alt={props.title}/>
                    </div>
                    <div className="therapy-description">
                        <h1 className="therapy-description-text">{props.title}</h1>
                        {props.description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Therapy;