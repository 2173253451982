import "../styles/HomeSchool.css";

const HomeSchool = () => {
    return (
        <>
        <section id="home-school"  >  
            
            <div className="puzzlecontainer">
            <img src="\images\MainPage\puzzle-blue-big.png" className="puzzle" alt=""></img>
             </div>
            <div className="title" data-aos="fade-up">
            DOMOWA SZKOŁA
            </div> 
           
            <div >
            <p className="quote" data-aos="fade-up">
                Miejsce spotkań, integracji oraz opieki dziennej dla dzieci i młodzieży w Edukacji Domowej.
            </p>    
            </div> 
            <div className="line" data-aos="fade-up">
            </div>
            
            <div className="container"data-aos="fade-up">
                <div className="column-left">
                            <div className="line2"></div>
                </div>
                <div className="column-middle">
                                <div className="misja">GŁÓWNE ZAŁOŻENIA:</div>
                                <div className ="descriptionText">
                                    <p>🔶
                                        <a href="https://dziecisawazne.pl/dlaczego-warto-uczyc-sie-w-szkolach-alternatywnych/">SZKOŁA ALTERNATYWNA </a>
                                        – oparta na założeniach alternatywnych filozofii edukacyjnych i nowatorskich rozwiązań pozasystemowych.
                                    </p>

                                    <p>🔷WSPÓŁPRACA – miejsce stworzone od podstaw z pomysłów i potrzeb rodziców i uczniów.</p>

                                    <p>🔶
                                        INSPIRACJE – 
                                        <a href="https://dziecisawazne.pl/jaka-jest-roznica-pomiedzy-metoda-montessori-a-tradycyjna-edukacja/"> pedagogika Montessori</a> 
                                        , elementy szkoły demokratycznej i 
                                        <a href="https://holistic.news/skandynawski-model-edukacji-niezwykla-forma-uczenia-zaradnosci/"> systemu skandynawskiego</a>. 
                                        </p>

                                    <p>🔷WARTOŚCI – zaufanie, współpraca, szacunek.</p>

                                    <p>🔶BRAK lekcji, sprawdzianów, ocen i zadań domowych. </p>

                                    <p>🔷WARSZTATY – rozwijanie umiejętności społecznych, uważności i zainteresowań.</p>
                                </div>

                </div>
                <div className="column-right">
                        <div className="carousel">
                            <img src="\images\HomeSchool\home-school.jpg" className="home-school" alt=""></img>
                        </div>
                </div>
            </div>
            <div className="container">
                <p className="home-school-desc">
                <b>Domowa Szkoła Montessori w Rybniku</b> to ciepłe, serdeczne miejsce z domową atmosferą, w którym dzieci 
                i młodzież czują się akceptowani i ważni, ich wybory są szanowane, mają prawo do popełniania błędów 
                i rozwijania swoich zainteresowań. 
                <br/><br/>
                Dla uczniów klas 1-3 w 
                <a href="https://domowa.edu.pl/czym-jest-edukacja-domowa/"> Edukacji Domowej </a>
                prowadzimy zajęcia w ramach <b>opieki dziennej</b>, 
                zgodnie z filozofią Montessori, w godzinach 8.30 – 14.30. Istnieje możliwość skorzystania z 
                zajęć w wybranych godzinach i dniach. 
                <br/><br/>
                Uczniom powyżej klasy 4 SP w Edukacji Domowej proponujemy <b>pomoc w realizacji podstawy programowej</b> w 
                formie kreatywnych warsztatów oraz możliwość rozwijania zainteresowań podczas zajęć dodatkowych, 
                spotkań z ciekawymi ludźmi, w trakcie wycieczek.
                <br/><br/>
                Dbamy o <b>dobrostan psychiczny</b> uczniów, współpracując z 
                <a href="https://centrum.terapii.com.pl/"> Centrum Terapii Łąka Wrażliwości </a>
                w Rybniku 
                oraz o <b>integrację z rówieśnikami</b>. Większość terapii jest prowadzona w Domowej Szkole Montessori.
                <br/><br/>
                Domowa Szkoła Montessori zaprasza wszystkich uczniów w Edukacji Domowej, a w szczególności osoby <b>wysoko-wrażliwe, 
                w spektrum autyzmu, mające problemy z integracją w dużych grupach</b>, ponieważ nasze miejsce opieki dziennej Montessori 
                w Rybniku sprzyja wyciszeniu, unika przebodźcowania i presji oraz koncentruje się na indywidualnych potrzebach każdego ucznia.
                </p>
            </div>


            </section>
            
        </>
        
        
    );
};

export default HomeSchool;
